import Watch from '@components/display/offer/WatchDynamic'
import styles from 'styles/OfferWatchMobileShift.module.css'

const WatchShiftOnMobile = ({ offer, style = {} }) => {
    return (
        <div className={styles.shift} style={style}>
            <Watch offer={offer} />
        </div>
    )
}

export default WatchShiftOnMobile