import Link from 'next/link'
import AdvancedImage from '@components/image/WithFallback'
import ShortSpecifications from '@components/display/offer/ShortSpecifications'
import WatchMobileShift from '@components/display/offer/WatchMobileShift'
import Price from '@components/price/Price'
import Icon from '@components/shared/Icon'
import coverPhoto from '@utils/links/coverPhoto'
import useT from '@hooks/useTranslation'
import mix from '@utils/styles/mix'

import userName from '@utils/user/userName'
import offerLink from '@utils/links/offerLink'
import listAlt from '@utils/alt/listAlt'
import makeYoutubeThumbnailResolver from '@utils/video/makeYoutubeThumbnailResolver'
import { useView, VIEWS } from '@context/ViewContext'

import styles from 'styles/AdList.module.css'

const PATH_DIFFERENCES = {
    '/samochody-dostawcze': '/dostawcze',
    '/czesci-auta-dostawcze': '/czesci',
    '/serwis-aut-dostawczych': '/serwisy-samochodowe-uslugi',
    '/narzedzia-warsztatowe-wyposazenie': '/narzedzia-warsztatowe-sprzedaz',
    '/wynajem': '/wynajem-samochodow-oferty',
}

function makeSamochodyLink (link, isDostawczakiLink) {
    if (isDostawczakiLink || !link) {
        return link
    } else {
        return 'https://samochody.pl' + replaceLinkParts(link, PATH_DIFFERENCES) + '?d=1'
    }
}

function replaceLinkParts (link, pathDifferences) {
    let _link = link
    for (const key in pathDifferences) {
        _link = _link.replace(key, pathDifferences[key])
    }
    return _link
}

const AdListItem = (props) => {
    const {
        fuel, mileage, production_year,
        options,
        data: { equipment, displacement_capacity } = {},
        promotion: { search_list_top } = {},
        price,
        user,
        video_link,
        tracking: { pid, city } = {},
        original, synced } = props

    const isHighlighted = Boolean(search_list_top?.on)
    const isDostawczakiLink = original || !synced
    const link = offerLink(props)
    const src = coverPhoto(pid)
    const [alt, caption] = listAlt(props)

    return (
        <Wrapper link={makeSamochodyLink(link, isDostawczakiLink)} isHighlighted={isHighlighted} isSamochodyLink={!isDostawczakiLink}>
            <Image {...{ alt, src, vid: video_link }} />
            <Description {...{ caption, options, equipment, spec: { fuel, mileage, production_year, displacement_capacity }}} />
            <WatchMobileShift offer={props} style={{ top: 10, right: -16 }} />
            <PriceLocation {...{ price, user, city }} />
        </Wrapper>
    )
}

export function Wrapper ({ children, link = '/', isHighlighted, isSamochodyLink }) {
    const linkProps = {
        className: mix([
            (isHighlighted ? styles.highlighted : styles.wrapper),
            isSamochodyLink && styles.samochody,
            styles.fade_in,
        ]),
        target: (isSamochodyLink ? '_blank' : undefined),
    }

    return (
        <Link href={link} prefetch={false}>
            <a {...linkProps}>
                { children }
            </a>
        </Link>
    )
}

export function Image ({ alt, src, vid }) {
    const youtubeResolver = vid && makeYoutubeThumbnailResolver(vid)
    return (
        <div className={styles.image_wrapper}>
            <AdvancedImage
                alt={alt}
                customFallbackResolver={youtubeResolver}
                layout="fill"
                objectFit="cover"
                src={src}
            />
            { vid && <img
                alt="Zawiera wideo"
                className={styles.sticker}
                height={33}
                src="/icons/vid.webp"
                title="Ogłoszenie zawiera film"
                width={41}
            /> }
        </div>
    )
}

export function Description ({ caption, options, equipment, spec }) {
    return (
        <div className={styles.description}>
            <h2>{caption}</h2>
            <Options items={options} />
            <Equipment items={equipment} />
            <ShortSpecifications {...spec} />
        </div>
    )
}

export function UserName ({ user }) {
    const name = userName(user)
    return <div className={styles.user_name}>{name || useT('Anonim')}</div>
}

export function City ({ city }) {
    if (typeof city !== 'string' || !city) return null
    return (
        <div className={styles.city}>
            <Icon>location_on</Icon>
            <h3>{city}</h3>
        </div>
    )
}

export function PriceLocation ({ price = {}, user = {}, city = {}, PriceComponent = Price }) {
    const view = useView()
    const isGrid = view === VIEWS.GRID
    return (
        <div className={styles.price_location}>
            <PriceComponent {...price} small={isGrid} />
            <UserName user={user} />
            <City city={city} />
        </div>
    )
}

function Options ({ items }) {
    return items && items.length > 0
        ? <span className={styles.opts}>{items.join(' , ')} ...</span>
        : null
}

function Equipment ({ items }) {
    return items
        ? <span className={styles.equip}>{useT('Wersja wyposażenia')}: {items}</span>
        : null
}

export default AdListItem