import useT from '@hooks/useTranslation'
import formatPrice from '@utils/price/format'

import priceStyles from 'styles/Price.module.css'

export default function Price ({
    price, installment,
    price_is_netto, price_is_negotiable, price_invoice,
    currency,
    small, noadditionals }) {

        if (price == null) return null

        return (
            <div className={priceStyles.price}>
                <AdditionalPriceInfo {...{price_is_negotiable, price_invoice, noadditionals}} />
                <Installment {...{installment, currency}} />
                <PriceValue {...{price, currency, small}} />
                <BruttoOrNetto netto={price_is_netto} />
            </div>
        )
    }

export function AdditionalPriceInfo ({ price_is_negotiable, price_invoice, noadditionals }) {
    if (noadditionals) return null
    if (!price_is_negotiable && !price_invoice) return null
    return (
        <div className={priceStyles.additional}>
            { price_invoice && <span>{ useT('faktura VAT')}</span> }
            { price_is_negotiable && <span>{ useT('do negocjacji') }</span> }
        </div>
    )
}

export function Installment ({ installment, currency = ''}) {
    if (!installment) return null
    return <span className={priceStyles.installment}><span>{useT('Rata')}</span> <b>{installment} {currency}</b></span>
}

export function PriceValue ({ price = 0, currency = '', format = true, className, small }) {
    return (
        <span className={className || (small
            ? `${priceStyles.price_value} ${priceStyles.small}`
            : priceStyles.price_value)}>

            {format
                ? formatPrice(price)
                : price } {currency}
        </span>
    )
}

export function BruttoOrNetto ({ netto = false }) {
    return <span className={priceStyles.brutto_netto}>{netto
        ? useT('netto')
        : useT('brutto')}</span>
}