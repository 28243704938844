import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import searchFromObject from '@utils/links/searchFromObject'
import Icon from '@components/shared/Icon'

import styles from 'styles/Pagination.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

const RouterPagination = ({
    children,
    onTop = true,
    atBottom = true, ...rest }) => {

    const router = useRouter()
    const { pathname, query } = pathDataFromRouter(router)

    const link = (page) => createLink(pathname, query, page)

    const buttons = <Buttons link={link} {...rest} />

    return (
        <>
            { onTop && buttons }
            { children }
            { atBottom && buttons }
        </>
    )
}

function Buttons ({ link,
    page, totalPages = 0, // totalDocs, perPage,
    onEachSide = 2 }) {

    if (totalPages < 2) return null

    let start = page - onEachSide
    let end = page + onEachSide

    if (start < 1) {
        end += (start * -1) + 1
        start = 1
    }

    if (end > totalPages) {
        end = totalPages
    }

    const buttons = []
    for (let i = start; i <= end; i++) {
        buttons.push(<Button
            key={i}
            page={i}
            link={link}
            active={i !== page}
            current={i === page}
        />)
    }

    const gapLeft = start > 1
    const gapRight = end < totalPages

    return (
        <nav className={styles.buttons}>
            <Previous {...{page, link}} />

            { gapLeft && <p>...</p> }

            { buttons }

            { gapRight && <p>...</p> }

            <Next {...{page, totalPages, link}} />
        </nav>
    )
}

function Button ({ page, link, active, current, display, alt }) {
    const className = active
        ? styles.button
        : current
            ? `${styles.button} ${styles.current}`
            : `${styles.button} ${styles.disabled}`

    alt = alt || `Strona nr. ${page}`

    return active
        ? <Link href={link(page)}>
            <a className={className} title={alt}>
                {display || page}
            </a>
          </Link>

        : <div className={className}>
                {display || page}
          </div>
}

function Previous ({ page, link }) {
    const active = page > 1
    const _page = active ? page - 1 : undefined
    return <>
        { active &&
            <PaginationHeadLink _key="pagi-prev" rel="prev" href={link(_page)} /> }
        <Button {...{
            page: _page,
            display: <Icon>chevron_left</Icon>,
            link,
            active,
            alt: 'Poprzednia strona',
        }} />
    </>
}

function Next ({ page, totalPages, link }) {
    const active = page < totalPages
    const _page = active ? page + 1 : undefined
    return <>
        { active &&
            <PaginationHeadLink _key="pagi-next" rel="next" href={link(_page)} /> }
        <Button {...{
            page: _page,
            display: <Icon>chevron_right</Icon>,
            link,
            active,
            alt: 'Następna strona',
        }} />
    </>
}

function PaginationHeadLink ({ _key, href, rel }) {
    return <Head>
        <link key={_key} rel={rel} href={`${HOST}${href}`} />
    </Head>
}

function createLink (pathname, query, page) {
    if (page > 1) {
        query.strona = page
    } else {
        delete query.strona
    }

    return `${pathname}${searchFromObject(query)}`
}

// strange that this is not required when simply derived from router.pathname for frontend links
// but in that case prev/next will be invalid, with param placeholders ex. /[location]
function pathDataFromRouter (router) {
    const query = { ...router.query }
    const pathname = router.pathname
        .split('/')
        .map(item => {
            if (isQueryParam(item)) {
                const param = getQueryParam(item)
                const chunk = encodeURIComponent(query[param])
                delete query[param]
                return chunk

            } else {
                return item
            }
        })
        .join('/')

    return { query, pathname }
}

function isQueryParam (item) {
    return item.startsWith('[') && item.endsWith(']')
}

function getQueryParam (item) {
    return item.slice(1, -1)
}



export default RouterPagination