import { FUEL_CAPTIONS } from '@constants/fuels'
import mix from '@utils/styles/mix'
import useT from '@hooks/useTranslation'
import styles from 'styles/OfferShortSpecifications.module.css'

function ShortSpecifications ({
    offer, displacement_capacity, mileage, fuel, production_year,
    showtext, altStyles }) {

    if (offer) {
        mileage = offer?.mileage
        fuel = offer?.fuel
        production_year = offer?.production_year
        displacement_capacity = offer?.data?.displacement_capacity
    }

    const className = altStyles?.specs
        ? mix([styles.specs, altStyles.specs])
        : styles.specs

    return (
        <div className={className}>
            <ShortSpecification icon="silnik" value={displacement_capacity} unit="cm³" alt="Pojemność silnika" showtext={showtext} />
            <ShortSpecification icon="przebieg" value={mileage} unit="km" alt="Przebieg" showtext={showtext} />
            <ShortSpecification icon="benzyna" value={FUEL_CAPTIONS[fuel] || fuel} alt="Paliwo" showtext={showtext} />
            <ShortSpecification icon="kalendarz" value={production_year} unit="r." alt="Rok produkcji" showtext={showtext} heading />
        </div>
    )
}

export function ShortSpecification ({ icon, alt, value, unit, heading, showtext }) {
    if (value == null || value === '') return null

    const text = unit
        ? `${value} ${unit}`
        : value

    return (
        <aside className={styles.spec}>
            { icon && <img src={`/svg/${icon}.svg`} alt={alt || value} width="29" height="25" />}
            { heading
                ? <h3>{showtext ? <small>{useT(alt)}:</small> : null} {text}</h3>
                : <span>{showtext ? <small>{useT(alt)}:</small> : null} {text}</span> }
        </aside>
    )
}

export default ShortSpecifications