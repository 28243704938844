// Youtube link variations:
// 'https://www.youtube.com/watch?v=Y0pHY6zsRMs'
// 'youtu.be/zsyp-Z_ARJo'
export default function extractYoutubeId (url) {
    if (!url || typeof url !== 'string') return url

    const FULL_PREFIX = 'watch?v='
    const SHORT_PREFIX = 'youtu.be/'

    const fullIndex = url.lastIndexOf(FULL_PREFIX)
    const shortenedIndex = url.indexOf(SHORT_PREFIX)

    let prefixIndex = -1
    if (fullIndex !== -1) {
        prefixIndex = fullIndex + FULL_PREFIX.length
    } else if (shortenedIndex !== -1) {
        prefixIndex = shortenedIndex + SHORT_PREFIX.length
    }

    return prefixIndex !== -1
        ? url.slice(prefixIndex)
        : ''
}