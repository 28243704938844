import extractYoutubeId from '@utils/video/extractYoutubeId'

const URL = 'https://img.youtube.com/vi'
const FORMAT = '.jpg'

export default function makeYoutubeThumbnailResolver (youtubeLink, size = '0') {
    return function fallbackResolver () {
        if (youtubeLink) {
            const id = extractYoutubeId(youtubeLink)
            if (id) return `${URL}/${id}/${size}${FORMAT}`
        }
    }
}