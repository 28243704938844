import zip from '@utils/array/zip'
import capitalize from '@utils/string/capitalize'

const buyCaptionFor = ({ makes, models, title, part_name }) =>
    (title || part_name || zip([makes, models])) || 'Bez nazwy'

const captionFor = ({ make, model, part_name, title }) =>
    (title || part_name || `${make || ''} ${model || ''}`.trim()) || 'Bez nazwy'

const listAlt = (offer = {}) => {
    const { production_year,
        data: { body_type, truck_body_type } = {}} = offer

    const caption = captionFor(offer)
    const body = capitalize(body_type || truck_body_type || '')
    const alt = [caption, body, production_year]
        .filter(Boolean)
        .join(' ')

    return [alt, caption, body]
}

export default listAlt
export {
    captionFor,
    buyCaptionFor,
}