import { createContext, useContext, useState } from 'react'

const SetViewContext = createContext(() => {})
const GetViewContext = createContext('')
const ViewContextProvider = ({ children, defaultView = '' }) => {
    const [view, setView] = useState(defaultView)
    return (
        <SetViewContext.Provider value={setView}>
            <GetViewContext.Provider value={view}>
                {children}
            </GetViewContext.Provider>
        </SetViewContext.Provider>
    )
}

export const useSetView = () => {
    return useContext(SetViewContext)
}

export const useView = () => {
    return useContext(GetViewContext)
}

export const VIEWS = {
    GRID: 'grid',
    LIST: 'list',
}

export default ViewContextProvider