import ListOut from '@components/shared/ListOut'
import AdListItem from '@components/lists/AdListItem'
import AdListEmpty from '@components/lists/AdListEmpty'
import Pagination from '@components/pagination/RouterPagination'
import Empty from '@components/shared/Empty'
import { useView } from '@context/ViewContext'
// import withBreakpoints from '@hoc/withBreakpoints'

import styles from 'styles/AdList.module.css'

const AdList = ({
    above,
    below,
    // breakpoints: { onMobile },
    ListItem = AdListItem,
    offers,
    pagination = {},
}) => (
    <Wrapper>
        <Pagination {...pagination} onTop={false}>
            { above }
            {offers.notFound && <AdListEmpty />}
            <ListView>
                <List offers={offers.fallback || offers} ListItem={ListItem} />
            </ListView>
        </Pagination>
        { below }
    </Wrapper>
)

export function StaticAdList ({ offers, ListItem = AdListItem }) {
    return (
        <List offers={offers} ListItem={ListItem} />
    )
}

function Wrapper ({ children }) {
    return (
        <div className="container" style={{ marginTop: 26, marginBottom: 60 }}>
            { children }
        </div>
    )
}

function ListView ({ children }) {
    const view = useView()
    return (
        <div className={styles[view]} data-view={view}>
            { children }
        </div>
    )
}

function List ({ offers, ListItem = AdListItem, ...rest }) {
    return offers?.length > 0
        ? <ListOut id="_id" items={offers} Component={ListItem} {...rest} />
        : <Empty />
}

function ListWrapper ({ children }) {
    return <div className={styles.list}>
        {children}
    </div>
}

export default AdList // withBreakpoints({ onMobile: 800 })
export {
    List,
    ListWrapper,
}